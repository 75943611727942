import { useEffect, useState } from 'react';
import { Card, Grid, CardContent } from '@mui/material';
import { withTheme } from '@emotion/react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { selectUsernameList } from 'modules/audit-logs/selectors';
import { auditLogsActions } from 'modules/audit-logs/slice';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { Paper } from './style';

/**
 * Audit logs filter view component define here
 * @param {*} param0
 * @returns
 */
const AuditLogsFilter = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const usernameList = useSelector(selectUsernameList);
  const organizationId = useSelector(selectOrganizationId);
  //
  const [username, setUsername] = useState([]);
  const [eventName, setEventName] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  //
  useEffect(() => {
    dispatch(
      auditLogsActions.usersList({
        organizationId,
        query: `limit=${PAGINATION.ALL_USERS_COUNT}`,
      })
    );
  }, []);
  //
  useEffect(() => {
    let query = `limit=${PAGINATION.AUDIT_LOGS_PER_PAGE_COUNT}&sortBy=createdAt:desc`;
    if (username) {
      query += `&${username.map((name) => `username=${name?.value}`).join('&')}`;
    }
    if (eventName) {
      query += `&eventName=${eventName}`;
    }
    if (startTime) {
      query += `&from=${startTime}`;
    }
    if (endTime) {
      query += `&to=${endTime}`;
    }
    dispatch(
      auditLogsActions.auditLogs({
        organizationId,
        query,
      })
    );
  }, [username, eventName, startTime, endTime]);
  //
  return (
    <Grid>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Paper sx={{ backgroundColor: theme.palette.background.default }}>
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
              <Autocomplete
                sx={{
                  maxWidth: {
                    xs: 80,
                    sm: 160,
                    md: 160,
                    lg: 240,
                    xl: 240,
                  },
                  display: 'flex',
                }}
                multiple
                fullWidth
                limitTags={1}
                id="tags-outlined"
                value={username}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={usernameList}
                getOptionLabel={(option) => option.value}
                onChange={(event, value) => setUsername(value)}
                filterSelectedOptions
                renderInput={(params) => <TextField label="Username" {...params} />}
              />
              <TextField
                sx={{
                  maxWidth: {
                    xs: 100,
                    sm: 200,
                    md: 200,
                    lg: 300,
                    xl: 300,
                  },
                  display: 'flex',
                }}
                name="eventName"
                value={eventName}
                fullWidth
                onChange={(event) => {
                  setEventName(event.target.value);
                }}
                label="Event Name"
              />
              <DateTimePicker
                slotProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                label="Start Time"
                value={startTime}
                onChange={(value) => setStartTime(value)}
                format="yyy/MM/dd hh:mm aa"
              />
              <DateTimePicker
                slotProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                label="End Time"
                value={endTime}
                onChange={(value) => setEndTime(value)}
                format="yyy/MM/dd hh:mm aa"
              />
            </Grid>
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  );
};
//
export default withTheme(AuditLogsFilter);
