import { CSVLink } from 'react-csv';
import { useState, useEffect, useRef } from 'react';
import { models } from 'powerbi-client';
import { useSelector } from 'react-redux';
import { differenceInDays, startOfDay } from 'date-fns';
import { selectAuthUser } from 'modules/common/auth/selectors';
import {
  selectBreakdown,
  selectHotelName,
  selectIsDeAggregate,
  selectTargetDate,
  selectTransactionBookingDate,
  selectTransactionDateType,
  selectTransactionSegmentFocusOn,
  selectVisualFilters,
} from 'modules/transactions/selectors';
import { getSubBreakdownFilter } from 'modules/transactions/components/inner-filter/functions';
import { isEmpty } from 'modules/common/helpers/object';
import { YEAR_MONTH_DAY_PATTERN, TIME_PATTERN } from 'modules/common/constants/date-range';
import { Grid, IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import { GenerateCSV, formatDate } from './functions';
import NoDataDialog from './components/no-data';

/**
 * Export Data component to download visual data in csv format
 * @returns
 */
const ExportToCSV = ({ report, isLoaded, fileName }) => {
  const csvLink = useRef();
  //
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  const stayDate = useSelector(selectTargetDate);
  const bookingDate = useSelector(selectTransactionBookingDate);
  const selectedDateType = useSelector(selectTransactionDateType);
  const isDeAggregate = useSelector(selectIsDeAggregate);
  const focusOn = useSelector(selectTransactionSegmentFocusOn);
  const chartBreakdown = useSelector(selectBreakdown);
  const visualFilters = useSelector(selectVisualFilters);
  //
  const [visualData, setVisualData] = useState([]);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  //
  useEffect(() => {
    // Set breakdown list
    const subBreakdownFilter = getSubBreakdownFilter(visualFilters);
    if (subBreakdownFilter) {
      setChartBreakdownList(subBreakdownFilter);
    }
  }, [visualFilters]);
  //
  useEffect(() => {
    if (visualData?.length) {
      setInitiateDownload(true);
    }
  }, [visualData]);
  //
  useEffect(() => {
    if (initiateDownload) {
      csvLink.current.link.click();
      setInitiateDownload(false);
    }
    setLoading(false);
  }, [visualData, initiateDownload]);
  //
  const resetInitData = () => {
    setInitiateDownload(false);
    setLoading(false);
    setIsDialog(true);
  };
  //
  const exportPbiData = async () => {
    setLoading(true);
    if (!isEmpty(report) && isLoaded) {
      //
      try {
        const pages = await report.getPages();
        const currentPage = pages.filter((page) => page.isActive)[0];
        const visuals = await currentPage.getVisuals();

        const visual = visuals.filter((v) => v.type === 'tableEx')[0];
        const result = await visual.exportData(models.ExportDataType.Summarized);
        if (result?.data?.length > 0) {
          result.data = result?.data.replaceAll(' 00:00:00', '');

          let meta = '';
          let csv = GenerateCSV(result, fileName);

          meta += `Username : , ${currentUser?.username}\r\n`;
          meta += `Report Generation Date : ,${formatDate(
            new Date(),
            YEAR_MONTH_DAY_PATTERN
          )} ${formatDate(new Date(), TIME_PATTERN)}\r\n`;
          meta += `Hotel , ${selectedHotel?.label}\r\n`;
          meta += `De-aggregated , ${isDeAggregate ? 'True' : 'False'}\r\n`;
          meta += `Breakdown , ${
            chartBreakdownList?.filter((bk) => bk.id === chartBreakdown)?.[0]?.label
          }\r\n`;
          meta +=
            focusOn?.length > 0
              ? `Focused Breakdown Segments , ${focusOn
                  ?.map((focus) => focus?.label)
                  ?.join(',')}\r\n`
              : '';
          meta += `${selectedDateType?.label} : ,  ${formatDate(
            stayDate?.startDate,
            YEAR_MONTH_DAY_PATTERN
          )} to ${formatDate(stayDate?.endDate, YEAR_MONTH_DAY_PATTERN)}\r\n`;
          meta += `Booking Date Range ,  ${formatDate(
            bookingDate?.startDate,
            YEAR_MONTH_DAY_PATTERN
          )} to ${formatDate(bookingDate?.endDate, YEAR_MONTH_DAY_PATTERN)}\r\n`;

          csv = `${meta} \r\n${csv} `;

          setVisualData(csv);
        } else {
          setMessage('No data available to export', resetInitData());
        }
      } catch (error) {
        console.log(error);
        setMessage('Something went wrong', resetInitData());
      }
    } else {
      setMessage('Visual is still loading', resetInitData());
    }
  };
  //
  return (
    <Loader loading={loading}>
      <Grid container direction="row" alignItems="center">
        <Typography
          sx={{
            mr: 1,
            color:
              selectedHotel?.id === 'All' ||
              differenceInDays(
                startOfDay(new Date(stayDate?.endDate)),
                startOfDay(new Date(stayDate?.startDate))
              ) !== 0
                ? 'gray'
                : 'black',
          }}
        >
          Export Data
        </Typography>
        <IconButton
          size="small"
          onClick={() => exportPbiData()}
          disabled={
            selectedHotel?.id === 'All' ||
            differenceInDays(
              startOfDay(new Date(stayDate?.endDate)),
              startOfDay(new Date(stayDate?.startDate))
            ) !== 0
          }
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1.25rem',
              height: '1.25rem',
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Grid>
      <CSVLink
        asyncOnClick
        filename={`${fileName}_${formatDate(new Date(), YEAR_MONTH_DAY_PATTERN)}`}
        data={visualData}
        target="_self"
        ref={csvLink}
        sx={{ display: 'none' }}
      />
      <NoDataDialog open={isDialog} onClose={() => setIsDialog(false)} message={message} />
    </Loader>
  );
};
//
export default ExportToCSV;
