/* eslint-disable react/no-this-in-sfc */
import { useState, useRef } from 'react';
import { endOfDay, startOfYear, startOfMonth, isSameDay, startOfDay, addDays } from 'date-fns';
import { InputAdornment } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { STAY_DATE_END_DATE, STAY_DATE_START_DATE } from 'modules/common/constants/date-range';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TextField } from './style';
/**
 * Custom stay date range picker common component that uses across modules for select stay date range
 * @param {Function} handleChange - function for setting data range
 * @param {Array} ranges - start date and end data of the picker
 * @param {String} label - textfield label
 * @param {String} adornment - whether we need to adornment is beginning or not
 * @param {String} dateValue - selected date value
 * @param {String} cssClassName - use style for entire component
 * @returns
 */
const CustomStayDateRangePicker = (props) => {
  // destruct the props object
  const { handleChange, ranges, label, adornment, dateValue, cssClassName, id = '' } = props;
  //
  const [open, setOpen] = useState(false);
  //
  const refTarget = useRef(null);
  // checks the range is exited or not and set date range
  const dateRange =
    ranges !== null
      ? [
          {
            startDate: new Date(ranges[0].startDate),
            endDate: new Date(ranges[0].endDate),
            key: 'selection',
          },
        ]
      : [
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          },
        ];
  //
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="calendarWrap">
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ minWidth: 90 }} position="start">
                {adornment}
              </InputAdornment>
            ),
          }}
          label={label}
          value={dateValue}
          size="small"
          color="primary"
          onClick={() => setOpen(!open)}
          fullWidth
        />
        <div ref={refTarget}>
          {open && (
            <DateRangePicker
              id={id}
              onChange={handleChange}
              editableDateInputs
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              months={1}
              direction="horizontal"
              className={cssClassName}
              maxDate={new Date(STAY_DATE_END_DATE)}
              minDate={new Date(STAY_DATE_START_DATE)}
              inputRanges={[]}
              dateDisplayFormat="yyyy-MM-dd"
              staticRanges={[
                defaultStaticRanges[0],
                {
                  label: 'Forward 7 days',
                  range: () => ({
                    startDate: addDays(startOfDay(new Date()), 0),
                    endDate: addDays(endOfDay(new Date()), 6),
                  }),
                  isSelected(range) {
                    const definedRange = this.range();
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    );
                  },
                },
                defaultStaticRanges[2],
                defaultStaticRanges[4],
                {
                  label: 'Month-to-Date',
                  range: () => ({
                    startDate: startOfMonth(new Date()),
                    endDate: endOfDay(new Date()),
                  }),
                  isSelected(range) {
                    const definedRange = this.range();
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    );
                  },
                },
                {
                  label: 'Year-to-Date',
                  range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: endOfDay(new Date()),
                  }),
                  isSelected(range) {
                    const definedRange = this.range();
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    );
                  },
                },
              ]}
            />
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
//
export default CustomStayDateRangePicker;
