import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
import AuthLayout from 'modules/common/layouts/auth';
import { Box, CircularProgress } from '@mui/material';
import { signInActions } from 'modules/sign-in/slice';
import openAuthWindow from 'modules/common/utils/auth';
import { selectSignInError } from 'modules/sign-in/selectors';
import getDashboardItems from '../../../common/components/sidebar/dashboard-Items';
import { selectAccessToken } from '../../../common/auth/selectors';
/**
 * Component that gets rendered on the callback URL
 * This function obtains the auth code or any error and sends that data back to the parent window,
 * and if this component was rendered as a parent (reset password flow), it re-initiates the auth flow and performs the sign-in
 */
const SignInAuthWindow = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signInError = useSelector(selectSignInError);
  const accessToken = useSelector(selectAccessToken);
  //
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  /**
   * The codeVerifier here is only used for the reset password flow and not for the normal auth flow
   * Note: localStorage had to be used since the page reload cause by re-initiation causes
   * the verifier to be reset
   */
  const loginCodeVerifier = localStorage.getItem('loginCodeVerifier');
  useEffect(() => {
    // Function to re-initiate the auth process after the reset password flow
    //
    const handleCodeExtract = async () => {
      // If this is a normal window (reset password flow) AND re-initialization of the auth process has already happenned once, trigger the login process
      if (code && loginCodeVerifier) {
        dispatch(signInActions.signIn({ code, codeVerifier: loginCodeVerifier }));
      }
    };
    handleCodeExtract();
  }, [error, code, loginCodeVerifier]);
  //
  useEffect(() => {
    if (isAuthenticated()) {
      const dashboardItems = getDashboardItems(accessToken);
      navigate(dashboardItems[0]?.pages[0]?.href);
    }
  }, [isAuthenticated]);
  //
  useEffect(() => {
    if (
      signInError === 'Code not valid' ||
      signInError === 'PKCE verification failed' ||
      signInError === 'PKCE invalid code verifier' ||
      signInError === 'PKCE verification failed: Code mismatch'
    ) {
      openAuthWindow();
    }
  }, [signInError]);
  //
  return (
    // Renders a loader with the common background in the reset password flow
    !window?.opener && (
      <AuthLayout>
        <Box sx={{ mb: 15, mt: 10, textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      </AuthLayout>
    )
  );
};
//
export default SignInAuthWindow;
